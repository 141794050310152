import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";

// const Login = lazy(() => import('../Pages/Authentication/Login'));
// const ForgetPasswordPage = lazy(() => import('../Pages/Authentication/ForgetPassword'))
// const Register = lazy(() => import('../Pages/Authentication/Register'));
// const UserProfile = lazy(() => import('../Pages/Authentication/user-profile'));
// const Loans = lazy(() => import('../Pages/Loans/Loans'))
// const Customer = lazy(() => import('./Customer'));
// const PageNotFound = lazy(() => import('../Pages/Utility/PageNotFound'));
// const User = lazy(() => import('./Admin/User'));
// const Role = lazy(() => import('./Admin/Role'));
// const Lender = lazy(() => import('./Admin/Lender'));
// const Partner = lazy(() => import('./Admin/Partner'));
// const ApiLog = lazy(() => import('./Admin/ApiLog'));
// const PortalUser = lazy(() => import('./Admin/PortalUser'));
// const Product = lazy(() => import('./Admin/Product'));
// const Onboarding = lazy(() => import('./Admin/Onboarding'));
// const Create = lazy(() => import('../Pages/Admin/OnboardingSystem/Create'));
// const HotLead = lazy(() => import('./HotLead'));
// const Team = lazy(() => import('./Admin/Team'));

import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";
import Loans from "../Pages/Loans/Loans";
import Customer from "./Customer";
import PageNotFound from "../Pages/Utility/PageNotFound";
import User from "./Admin/User";
import Role from "./Admin/Role";
import Lender from "./Admin/Lender";
import Partner from "./Admin/Partner";
import ApiLog from "./Admin/ApiLog";
import PortalUser from "./Admin/PortalUser";
import Product from "./Admin/Product";
import Onboarding from "./Admin/Onboarding";
import Battery from "./Oem/Battery";
import Charger from "./Oem/Charger";
import ChargingStation from "./Oem/ChargingStation";
import Manufacture from "./Oem/Manufacture";
import Vehicle from "./Oem/Vehicle";

import Create from "../Pages/Admin/OnboardingSystem/Create";
import HotLead from "./HotLead";
import Team from "./Admin/Team";
import ProductScheme from "./Admin/ProductScheme";
import Task from "./Task";
import Agreement from "./Admin/Agreement";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/manufacture/*", component: <Manufacture /> },
  { path: "/vehicle/*", component: <Vehicle /> },
  { path: "/battery/*", component: <Battery /> },
  { path: "/charging-station/*", component: <ChargingStation /> },
  { path: "/charger/*", component: <Charger /> },
  { path: "/userprofile", component: <UserProfile /> },
  { path: "/customers/*", component: <Customer /> },
  { path: "/disbursed/list", component: <Loans />},
  { path: "/users/*", component: <User />},
  { path: "/products/*", component: <Product />},
  { path: "/roles/*", component: <Role />},
  { path: "/lenders/*", component: <Lender />},
  { path: "/partners/*", component: <Partner />},
  { path: "/api_logs/*", component: <ApiLog />},
  { path: "/portal_users/*", component: <PortalUser /> },
  { path: "/onboarding-system/*", component: <Onboarding />},
  { path: "/hot_leads/*", component: <HotLead />},
  { path: '/teams/*', component: <Team />},
  { path: '/product_scheme/*', component: <ProductScheme />},
  { path: '/task/*', component: <Task />},
  { path: '/agreement/*', component: <Agreement />},
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  // { path: "/onboarding-system/create", component: <Create />},
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/404", component: <PageNotFound /> },
];

export { authProtectedRoutes, publicRoutes };
