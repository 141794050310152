import React from 'react';
import { Link } from 'react-router-dom';

const columns = (permission) => {
    const data = [
        {
            name: <span className='font-weight-bold fs-13'>SR No.</span>,
            selector: row => row?.index || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Station Name</span>,
            selector: row => (permission?.CHARGING_STATION?.DETAILS?.VIEW_CHARGING_STATION_DETAILS ? <Link to={`/charging-station/details/${row?.id}`}>{row?.stationName || '-'}</Link> : row?.stationName),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Station ID</span>,
            selector: row => row?.stationId || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>City Name</span>,
            selector: row => row?.cityName || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Address</span>,
            selector: row => row?.address || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Pincode</span>,
            selector: row => row?.pinCode || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Contact Person</span>,
            selector: row => row?.contactPerson || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Mobile Number</span>,
            selector: row => row?.mobileNumber || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Company Name</span>,
            selector: row => row?.companyName || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Created Date</span>,
            selector: row => new Date(row?.createdAt).toLocaleDateString() || '-',
            sortable: false
        },
     

    ];

    return data;
}

export default columns;
