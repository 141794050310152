import { createSlice } from "@reduxjs/toolkit";

export const role = createSlice({
    name: 'role',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getRoleList: (state, action) => {
            state.loading = true;
        },
        getRoleListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload.data;
            state.totalList = action?.payload?.count;
        },
        getRoleListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

export const user = createSlice({
    name: 'user',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getUserList: (state, action) => {
            state.loading = true;
        },
        getUserListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getUserListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

export const team = createSlice({
    name: 'team',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getTeamList: (state, action) => {
            state.loading = true;
        },
        getTeamListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getTeamListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getTeamDetails: (state, action) => {
            state.loading = true;
            state.details = {};
        },
        getTeamDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
        },
        getTeamDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateTeamDetails: (state, action) => {
            state.loading = true;
        },
        updateTeamDetailsSuccess: (state, action) => {
            state.loading = false;
        },
        updateTeamDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        addMemberInTeam: (state, action) => {
            state.loading = true;
        },
        addMemberInTeamSuccess: (state, action) => {
            state.loading = false;
        },
        addMemberInTeamError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        toggleMemberActivation: (state, action) => {
            state.loading = true;
        },
        toggleMemberActivationSuccess: (state, action) => {
            state.loading = false;
        },
        toggleMemberActivationError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

export const product = createSlice({
    name: 'product',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getProductList: (state, action) => {
            state.loading = true;
        },
        getProductListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getProductListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getProductDetails: (state, action) => {
            state.loading = true;
        },
        getProductDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
        },
        getProductDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateProductConfig: (state, action) => {
            state.loading = true;
        },
        updateProductConfigSuccess: (state, action) => {
            state.loading = false;
        },
        updateProductConfigError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})

export const lender = createSlice({
    name: 'lenderList',
    initialState: {
      list: [],
      totalList: 0,
      loading: false,
      error: null,
      details: {
       extraDetails:[]
      },
      config: {}
    },
    reducers: {
      getLenderList: (state, action) => {
        state.loading = true;
      },
      getLenderListSuccess: (state, action) => {
        state.loading = false;
        state.list = action?.payload.data;
        state.totalList = action?.payload?.count;
      },
      getLenderListFailure: (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      },
      getLenderDetails: (state, action) => {
        state.loading = true;
        state.details = {};
        state.config = {};
      },
      getLenderExtraDetails:(state, action) => {
        state.loading = true;
      },
      addLenderNewAddress:(state, action) => {
        state.loading = true;
      },
      addLenderNewAddressSuccess:(state, action) => {
        state.loading = false;
      },
      getLenderExtraDetailsSuccess: (state, action) => {
        state.loading = false;
        state.details = {...state?.details,extraDetails:action?.payload}
      },
      getLenderDetailsSuccess: (state, action) => {
        state.loading = false;
        state.details = action?.payload;
        state.config = action?.payload?.config;
      },
      getLenderDetailsError: (state, action) => {
        state.loading = false;
      },
      updateLenderConfig: (state, action) => {
        state.config = action?.payload;
      }
    }
});

export const partner = createSlice({
    name: 'partner',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getPartnerList: (state, action) => {
            state.loading = true;
        },
        getPartnerListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getPartnerListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

export const productScheme = createSlice({
    name: 'productScheme',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getProductSchemeList: (state, action) => {
            state.loading = true;
        },
        getProductSchemeListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
            state.error = null;
        },
        getProductSchemeListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getSchemeDetails: (state, action) => {
            state.loading = true;
        },
        getSchemeDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
            state.error = null;
        },
        getSchemeDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateSchemeConfig: (state, action) => {
            state.loading = true;
        },
        updateSchemeConfigSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        updateSchemeConfigError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

export const agreement = createSlice({
    name: 'agreement',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getAgreementList: (state, action) => {
            state.loading = true;
        },
        getAgreementListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
            state.error = null;
        },
        getAgreementListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getAgreementDetails: (state, action) => {
            state.loading = true;
        },
        getAgreementDetailsSuccess: (state, action) => {
            state.loading = false;
            state.details = action?.payload;
            state.error = null;
        },
        getAgreementDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateAgreementDetails: (state, action) => {
            state.loading = true;
        },
        updateAgreementDetailsSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        updateAgreementDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        approveAgreement: (state, action) => {
            state.loading = true;
        },
        approveAgreementSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        approveAgreementError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const portalUser = createSlice({
    name: 'portalUser',
    initialState: {
        list: [],
        totalList: 0,
        loading: false,
        error: null,
        details: {}
    },
    reducers: {
        getPortalUserList: (state, action) => {
            state.loading = true;
        },
        getPortalUserListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getPortalUserListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }    
})


// actions
export const {
    getRoleList,
    getRoleListSuccess,
    getRoleListError
} =  role?.actions;
export const { 
    getLenderList, 
    getLenderListSuccess, 
    getLenderListFailure,
    getLenderDetails,
    getLenderDetailsSuccess,
    getLenderDetailsError,
    updateLenderConfig,
    getLenderExtraDetails,
    getLenderExtraDetailsSuccess,
    addLenderNewAddress,
    addLenderNewAddressSuccess
} = lender?.actions;
export const { 
    getPartnerList, 
    getPartnerListSuccess, 
    getPartnerListError
} = partner?.actions;
export const { 
    getUserList, 
    getUserListSuccess, 
    getUserListError
} = user?.actions;
export const { 
    getTeamList, 
    getTeamListSuccess, 
    getTeamListError,
    getTeamDetails,
    getTeamDetailsSuccess,
    getTeamDetailsError,
    updateTeamDetails,
    updateTeamDetailsSuccess,
    updateTeamDetailsError,
    addMemberInTeam,
    addMemberInTeamSuccess,
    addMemberInTeamError,
    toggleMemberActivation,
    toggleMemberActivationSuccess,
    toggleMemberActivationError
} = team?.actions;
export const { 
    getProductSchemeList, 
    getProductSchemeListSuccess, 
    getProductSchemeListError,
    getSchemeDetails,
    getSchemeDetailsSuccess,
    getSchemeDetailsError,
    updateSchemeConfig,
    updateSchemeConfigSuccess,
    updateSchemeConfigError,
} = productScheme?.actions;
export const { 
    getAgreementList,
    getAgreementListSuccess,
    getAgreementListError,
    getAgreementDetails,
    getAgreementDetailsSuccess,
    getAgreementDetailsError,
    updateAgreementDetails,
    updateAgreementDetailsSuccess,
    updateAgreementDetailsError,
    approveAgreement,
    approveAgreementSuccess,
    approveAgreementError
} = agreement?.actions;
export const {
    getProductList,
    getProductListSuccess,
    getProductListError,
    getProductDetails,
    getProductDetailsSuccess,
    getProductDetailsError,
    updateProductConfig,
    updateProductConfigSuccess,
    updateProductConfigError
} = product?.actions;
export const {
    getPortalUserList,
    getPortalUserListSuccess,
    getPortalUserListError
} = portalUser?.actions;

// reducers
export const roleReducer = role?.reducer;
export const lenderListReducer = lender?.reducer;
export const partnerReducer = partner?.reducer;
export const userReducer = user?.reducer;
export const teamReducer = team?.reducer;
export const productSchemeReducer = productScheme?.reducer;
export const agreementReducer = agreement?.reducer;
export const productReducer = product?.reducer;
export const portalUserReducer = portalUser?.reducer;


