import React, { useState, useEffect } from 'react';
import { Input, Label, Modal } from 'reactstrap';
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
import Dropdown from '../../../components/Dropdown/Dropdown';

// Dummy data for state and city options
const stateOptions = [
  { label: 'State1', value: 1 },
  { label: 'State2', value: 2 }
];

const cityOptions = [
  { label: 'City1', value: 1 },
  { label: 'City2', value: 2 }
];

const CreateChargingStation = (props) => {
  const { toaster, oemServer, navigate } = useClass();
  const { isOpen, setIsOpen } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    // cpoId: '',
    // chargerId: '',
    // stationId: '',
    // lat: '',
    // lng: '',
    // discomId: '',
    // csDisplayno: '',
    // stationName: '',
    // address: '',
    // cityName: '',
    // pinCode: '',
    // cityId: '',
    // stateId: '',
    // stOwner: '',
    // companyName: '',
    // mobileNumber: '',
    // contactPerson: '',
    // amenities: '',
    // avgCostDiscom: '',
    // openingTime: '',
    // closingTime: '',
    // isTweentyFourSeven: '',
    wkStatus: false,
    isFourWheeler: false
  });
  const handleCheckbox = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }));
  };


  const handleModelClose = () => {
    setIsOpen(false);
  };

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleChargingStationCreation = async () => {
    try {
      setIsLoading(true);
      if (validateForm()) {
        const payload = {
          ...form,
          pinCode: parseInt(form.pinCode, 10), // Ensure pinCode is a number
          lat: parseFloat(form.lat), // Ensure lat is a number
          lng: parseFloat(form.lng), // Ensure lng is a number
          csDisplayno: parseInt(form.csDisplayno, 10) // Ensure csDisplayno is a number
        };
        const response = await oemServer.createChargingStation(payload);
        setIsLoading(false);
        if (response?.data?.success) {
          handleModelClose();
          navigate(`/charging-station/details/${response?.data?.data?.id}`);
          toaster.show(false, 'Charging station created successfully');
        } else {
          throw new Error(response?.data?.message);
        }
      } else {
        throw new Error('Please fill all required fields');
      }
    } catch (error) {
      setIsLoading(false);
      toaster.show(true, error?.message);
    }
  };

  const validateForm = () => {
    const result = Object.keys(form).every((key) => {
      return form.hasOwnProperty(key) && form[key] !== '';
    });
    return result;
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Create Charging Station
          </h5>
          <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {/* <div>
            <TextInput
              label={'CPO ID'}
              name={'cpoId'}
              value={form.cpoId}
              handleInput={handleInput}
              type={'number'}
            />
          </div> */}
{/* 
          <div className='mt-4'>
            <TextInput
              label={'Charger ID'}
              name={'chargerId'}
              value={form.chargerId}
              handleInput={handleInput}
              type={'text'}
            />
          </div> */}

          {/* <div className='mt-4'>
            <TextInput
              label={'Station ID'}
              name={'stationId'}
              value={form.stationId}
              handleInput={handleInput}
              type={'text'}
            />
          </div> */}

          <div className='mt-4'>
            <TextInput
              label={'Latitude'}
              name={'lat'}
              value={form.lat}
              handleInput={handleInput}
              type={'number'}
              step="0.00001"
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Longitude'}
              name={'lng'}
              value={form.lng}
              handleInput={handleInput}
              type={'number'}
              step="0.00001"
            />
          </div>
{/* 
          <div className='mt-4'>
            <TextInput
              label={'Discom ID'}
              name={'discomId'}
              value={form.discomId}
              handleInput={handleInput}
              type={'number'}
            />
          </div> */}

          <div className='mt-4'>
            <TextInput
              label={'CS Display No'}
              name={'csDisplayno'}
              value={form.csDisplayno}
              handleInput={handleInput}
              type={'text'}
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Station Name'}
              name={'stationName'}
              value={form.stationName}
              handleInput={handleInput}
              type={'text'}
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Address'}
              name={'address'}
              value={form.address}
              handleInput={handleInput}
              type={'text'}
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'City Name'}
              name={'cityName'}
              value={form.cityName}
              handleInput={handleInput}
              type={'text'}
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Pin Code'}
              name={'pinCode'}
              value={form.pinCode}
              handleInput={handleInput}
              type={'number'}
            />
          </div>
{/* 
          <div className='mt-4'>
            <Dropdown
              label={'City'}
              name={'cityId'}
              value={cityOptions.find(option => option.value === form.cityId)}
              handleInput={handleInput}
              options={cityOptions}
            />
          </div> */}

          {/* <div className='mt-4'>
            <Dropdown
              label={'State'}
              name={'stateId'}
              value={stateOptions.find(option => option.value === form.stateId)}
              handleInput={handleInput}
              options={stateOptions}
            />
          </div> */}

          <div className='mt-4'>
            <TextInput
              label={'Owner'}
              name={'stOwner'}
              value={form.stOwner}
              handleInput={handleInput}
              type={'text'}
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Company Name'}
              name={'companyName'}
              value={form.companyName}
              handleInput={handleInput}
              type={'text'}
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Mobile Number'}
              name={'mobileNumber'}
              value={form.mobileNumber}
              handleInput={handleInput}
              type={''}
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Contact Person'}
              name={'contactPerson'}
              value={form.contactPerson}
              handleInput={handleInput}
              type={'text'}
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Amenities'}
              name={'amenities'}
              value={form.amenities}
              handleInput={handleInput}
              type={'text'}
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Average Cost (Discom)'}
              name={'avgCostDiscom'}
              value={form.avgCostDiscom}
              handleInput={handleInput}
              type={'text'}
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Opening Time'}
              name={'openingTime'}
              value={form.openingTime}
              handleInput={handleInput}
              type={'text'}
            />
          </div>

          <div className='mt-4'>
            <TextInput
              label={'Closing Time'}
              name={'closingTime'}
              value={form.closingTime}
              handleInput={handleInput}
              type={'text'}
            />
          </div>

          {/* <div className='mt-4'>
            <TextInput
              label={'24/7 Availability'}
              name={'isTweentyFourSeven'}
              value={form.isTweentyFourSeven}
              handleInput={handleInput}
              type={'text'}
            />
          </div> */}


          <div className='mt-4'>
          <Label check>
            <Input
              type="checkbox"
              id="wkStatus"
              name="wkStatus"
              checked={form.wkStatus}
              onChange={(e) => handleCheckbox('wkStatus', e.target.checked)}
            />
            WK Status
          </Label>
          </div>

          <div className='mt-4'>
          <Label check>
            <Input
              type="checkbox"
              id="isFourWheeler"
              name="isFourWheeler"
              checked={form.isFourWheeler}
              onChange={(e) => handleCheckbox('isFourWheeler', e.target.checked)}
            />
            Four Wheeler
          </Label>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <LoadingButton
            buttonContent={'Create'}
            isLoading={isLoading}
            onClick={handleChargingStationCreation}
            color={'success'}
          />
        </div>
      </Modal>
    </>
  );
};

export default CreateChargingStation;
