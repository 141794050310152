import React from "react"
import ReactApexChart from "react-apexcharts"

const Apaexlinecolumn = ({categories}) => {

  const ApaexlinecolumnData = {
    series: [
      {
        name: "Revenue",
        data: [46, 57, 59, 10],
      },
      {
        name: "Expense",
        data: [74, 83, 102, 20],
      },
      {
        name: "Net profit",
        data: [37, 42, 38, 10],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "45%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
  
  // "#f46a6a"
  
      colors: ["rgb(239, 242, 247)", "rgb(61, 142, 248)", "#34c38f"],
      xaxis: {
        categories: categories,
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          },
        },
      },
    },
  }

  return(
    <React.Fragment>
        <ReactApexChart
          options={ApaexlinecolumnData.options}
          series={ApaexlinecolumnData.series}
          type="bar"
          height={350}
          className="apex-charts"
        />
      </React.Fragment>
  )
}

export default Apaexlinecolumn;
