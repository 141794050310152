import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Accordion,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap";
import useClass from "../../../hooks/useClass";
import LoadingButton from "../../../components/Common/LoadingButton";
import { validateConfig, validateNestedObject } from "../../../utils/helper";
import ConfigCanban from "../../../components/Config-Canban/ConfigCanban";
import { lenderConfigFields } from "../../../constants/constants";
import {
  getLenderDetails,
  getLenderExtraDetails,
  getProductSchemeList,
} from "../../../store/admin/adminData";
import { useSelector } from "react-redux";
import CreateLenderAddress from "./CreateLenderAddress";

const Details = () => {
  const { adminServer, toaster, permission, dispatch } = useClass();
  const { details } = useSelector((state) => state?.lender);
  const { list } = useSelector((state) => state?.productScheme);
  const [isLoading, setIsLoading] = useState(false);
  const [updateLenderBasicDetails, setUpdateLenderBasicDetails] = useState({});
  const [lenderDetails, setLenderDetails] = useState({});
  const { lenderCode } = useParams();
  const [form, setForm] = useState({});
  const [selectedConfigFields, setSelectedConfigFields] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [createLenderAddress, setCreateLenderAddress] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    dispatch(getLenderDetails(lenderCode));
    dispatch(getLenderExtraDetails(lenderCode));
    dispatch(getProductSchemeList());
  }, []);

  useEffect(() => {
    if (details) {
      setLenderDetails(details);
      setForm(details?.config || {});
    }
  }, [details]);

  const handleLenderDetailsChange = (key, value) => {
    if (permission?.LENDERS?.DETAILS?.UPDATE_LENDER_DETAILS) {
      setLenderDetails((prev) => ({
        ...prev,
        [key]: value,
      }));
      setUpdateLenderBasicDetails((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      toaster.show(true, "You dont have permission");
    }
  };

  const handleLenderDetailsUpdate = async () => {
    try {
      const result = await toaster.confirmation("Update");
      if (result?.isConfirmed) {
        setIsLoading(true);
        if (
          Object?.keys(updateLenderBasicDetails)?.length > 0 &&
          updateLenderBasicDetails?.isActive !== lenderDetails?.isActive
        )
          handleBasicInfoUpdate();
        const valid = validateNestedObject(form, selectedConfigFields);
        if (valid) {
          if (lenderDetails?.config) {
            handleConfigUpdate();
          } else {
            handleConfigCreation();
          }
        } else {
          throw new Error("Please fill all selected config");
        }
      }
    } catch (error) {
      setIsLoading(false);
      toaster.show(true, error?.message);
    }
  };

  const handleBasicInfoUpdate = async () => {
    try {
      const payload = {
        isActive: lenderDetails?.isActive,
      };
      const response = await adminServer.updateLender(lenderCode, payload);
      setIsLoading(false);
      if (response?.data?.success) {
        toaster.show(false, "Lender Info updated successfully");
        // window.location.reload();
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      throw new Error(error?.message);
    }
  };

  const handleConfigCreation = async () => {
    try {
      const response = await adminServer.createLenderConfig({
        lenderCode,
        config: form,
      });
      if (response?.data?.success) {
        toaster.show(false, "Configuration created succesfully");
        window.location.reload();
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      throw new Error(error?.message);
    }
  };

  const handleConfigUpdate = async () => {
    try {
      const response = await adminServer.updateLenderConfig(
        lenderDetails?.configId,
        { config: form }
      );
      setIsLoading(false);
      if (response?.data?.success) {
        toaster.show(false, "Configuration updated succesfully");
        window.location.reload();
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      throw new Error(error?.message);
    }
  };

  const handleConfigInput = (key, value) => {
    const keys = key?.split(" ");
    setForm((prev) => {
      if (keys?.length === 1) {
        return {
          ...prev,
          [key]: value,
        };
      }
      if (keys?.length === 2) {
        return {
          ...prev,
          [keys[0]]: {
            ...prev?.[keys?.[0]],
            [keys[1]]: value,
          },
        };
      }
      if (keys?.length === 3) {
        return {
          ...prev,
          [keys[0]]: {
            ...prev?.[keys[0]],
            [keys[1]]: {
              ...prev?.[keys[0]]?.[keys[1]],
              [keys[2]]: value,
            },
          },
        };
      }
    });
  };
console.log("details",details?.extraDetails)
  return (
    <div>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="lenders" breadcrumbItem="Lender Details" />
          <Row>
            <Col xl={12}>
              <Card className="common-card-style">
                <CardHeader className="d-flex justify-content-between">
                  <div className="section-heading">Lender Details</div>
                  {/* <Button color="primary" size="sm">Add More Details</Button> */}
                  <div className="form-check form-switch form-switch-md">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      checked={lenderDetails?.isActive || false}
                      onChange={(e) =>
                        handleLenderDetailsChange("isActive", e.target.checked)
                      }
                    />
                    <label
                      className="form-check-label"
                      style={
                        lenderDetails?.isActive
                          ? { color: "green" }
                          : { color: "red" }
                      }
                    >
                      {lenderDetails?.isActive ? "Active" : "In-Active"}
                    </label>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Lender Name</Row>
                      <Row className="data-value me-4">
                        {lenderDetails?.name}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Lender Code</Row>
                      <Row className="data-value me-4">
                        {lenderDetails?.code}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created By</Row>
                      <Row className="data-value me-4">
                        {lenderDetails?.createdBy}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created Date</Row>
                      <Row className="data-value me-4">
                        {new Date(
                          lenderDetails?.createdAt
                        ).toLocaleDateString() || "-"}
                      </Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {permission?.LENDERS?.DETAILS?.VIEW_LENDER_DETAILS && <Card className="mb-4 shadow-sm rounded">
            <CardHeader className="bg-light">
            <div className="d-flex justify-content-between">
              <p className="section-heading">Lender Address Details</p>
              {permission?.LENDERS?.LIST?.CREATE_NEW_LENDER && <Button onClick={()=> setCreateLenderAddress(true)} color="success" >
               <i className='bx bx-plus-medical' />
                Add New Address
                </Button>}
              </div>
            </CardHeader>
            <CardBody>
              {details?.extraDetails?.map((details, index) => (
                <Card key={index} className="mb-3 p-3 shadow-sm rounded">
                  <CardHeader className="bg-primary text-white">
                    <h6 className="mb-0">{details?.state}</h6>
                  </CardHeader>
                  <CardBody>
                    <Row className="mb-2">
                      <Col lg={4} md={6} sm={12}>
                        <div className="fw-bold text-muted">SGST</div>
                        <div>{details?.SGST}</div>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <div className="fw-bold text-muted">CGST</div>
                        <div>{details?.CGST}</div>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <div className="fw-bold text-muted">IGST</div>
                        <div>{details?.IGST}</div>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col lg={4} md={6} sm={12}>
                        <div className="fw-bold text-muted">Address</div>
                        <div>{details?.address}</div>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <div className="fw-bold text-muted">GST Number</div>
                        <div>{details?.gstNumber}</div>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <div className="fw-bold text-muted">State</div>
                        <div>{details?.state}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} md={6} sm={12}>
                        <div className="fw-bold text-muted">Created By</div>
                        <div>{details?.createdBy}</div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </CardBody>
          </Card>}
          
          <Row>
            <ConfigCanban
              fields={lenderConfigFields({
                schemeList: list?.map((data) => ({
                  ...data,
                  label: data?.code,
                  value: data?.code,
                })),
              })}
              form={form}
              setForm={setForm}
              handleInput={handleConfigInput}
              setSelectedConfigFields={setSelectedConfigFields}
            />
          </Row>
          {permission?.LENDERS?.DETAILS?.UPDATE_LENDER_DETAILS && (
            <Row className="mt-4">
              <Col className="d-flex justify-content-center gap-4">
                <LoadingButton
                  color={"success"}
                  onClick={handleLenderDetailsUpdate}
                  buttonContent={"Update"}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          )}
        </Container>
        <CreateLenderAddress lenderCode={lenderCode} isOpen={createLenderAddress} toggle={setCreateLenderAddress}  />
      </Row>
    </div>
  );
};

export default Details;
