export const customerData = {
    name: 'Name',
    email: 'Email',
    mobile: 'Mobile',
    gender: 'Gender',
    createdBy: 'Created By'
}

export const entityData = {
    businessName: 'Business Name',
    legalName: 'Legal Name',
    entityType: 'Entity Type',
    typeOfBusiness: 'Type Of Business',
    businessCategory: 'Business Category',
    businessIncorporationYear: 'Business Incorporation Year',
    entityPan: 'Entity PAN',
    gst: 'GSTIN',
    cin: "CIN",
    udyam: 'Udyam',
    existingVehicles: 'Existing Vehicles',
    revenueLastAudited: 'Last Audited Revenue',
    pbtLastAudited: 'Last Audited PBT',
    lastProvisionalRevenue: 'Last Provisional Revenue',
    pbtLastProvisional: 'Last Provisional PBT',
    projectedRevenue: 'Projected Revenue',
    pbtProjected: 'Projected PBT'
}

export const entityDocumentsData = {
    'financialStatementLastFY': 'Last FY financial statement',
    'financialStatementLastFY-1': `Financial Statement ${new Date().getFullYear - 1}`,
    'financialStatementLastFY-2': `Financial Statement ${new Date().getFullYear - 2}`,
    'bankStatement': 'Bank Statement',
    'loanStatementForExistingLoan': 'Existing loan statement',
    'incomeTaxReturn': 'Income tax return',
    'gstReturn': 'GST return',
    'sanctionLetterOfLoanLease': 'Sanction Letter of Lease Loan',
    'unitEconomics': 'Unit economics',
    'shareHolding': 'Shareholding pattern',
    'companyProfile': 'Company profile',
    'memorandumOfAssociation': 'Memorandum of Association',
    'partnershipDeed': 'Partnership Deed',
    'balanceSheet': 'Balance Sheet',
    'profitLossStatement': 'PnL statement',
    'projections': 'Projections',
    'revenueBreakup': 'Contract'
}

export const entityDirectorsData = {
    name: 'Name',
    mobile: 'Mobile',
    din: 'DIN',
    aadhaar: 'Aadhaar',
    pan: 'PAN',
    kycStatus: 'KYC Status'
}

export const applicationData = {
    applicationId: 'Application ID',
    productName: 'Product',
    productCode: 'Product code',
    status: 'Status',
    numberOfVehicleRequired: 'Number Of vehicles Required',
    manufacturer: 'Manufacturer',
    model: 'Model',
    variant: 'Variant',
    amountRequired: 'Requested amount',
    city: 'City',
    relationshipWithNominee: 'Relation with nominee',
    nomineeName: 'Nominee name',
    address: 'Address',
    pincode: 'Pincode',
    gender: 'Gender',
    dateOfBirth: 'Date of birth',
    purposeOfVisit: 'Purpose of visit',
    productType: 'Product type',
    rtoCode: 'RTO code',
    registrationNumber: 'Registration number',
    fuelType: 'Fuel type',
    seatingCapacity: 'Seating capacity',
    mfy: 'MFY',
    policyType: 'Policy type',
    nilDep: 'NIL dep',
    rti: 'RTI',
    rsa: 'RSA',
    eme: 'EME',
    batteryCover: 'Battery cover',
    cpa: 'CPA',
    imt23: 'IMT23',
    ownerDriver: 'Owner driver'
}

export const loanData = {
    loanUid: 'Loan ID',
    applicationId: 'Application ID',
    customerCode: 'Customer Code',
    lenderCode: 'Lender Code',
    statusCode: 'Loan Status',
    amountRequested: 'Requested Amount',
    amountApproved: 'Approved Amount',
    amountDisbursed: 'Disbursed Amount',
    amountRepaid: 'Repaid Amount',
    createdBy: 'Created By'
}

export const reKycFieldData = [
    'cin', 
    'udyam', 
    'gst',
    'businessIncorporationYear', 
    'pbtProjected', 
    'businessCategory', 
    'entityType',
    'typeOfBusiness', 
    'existingVehicles',
    'projectedRevenue',
    'pbtLastProvisional',
    'lastProvisionalRevenue',
    'pbtLastAudited',
    'revenueLastAudited',
    'aadhaar',
    'pan',
    'numberOfVehicleRequired',
    'amountRequired',
    'city',
    'manufacturer',
    'model',
    'variant',
    'relationshipWithNominee',
    'nomineeName',
    'address',
    'pincode',
    'gender',
    'dateOfBirth'
]

export const fieldStatusConstants = {
    'VERIFIED': {
        className: 'fas fa-check-circle',
        color: 'green'
    },
    'REJECTED': {
        className: 'fas fa-times-circle',
        color: 'red'
    },
    'PENDING': {
        className: 'fas fa-question-circle',
        color: ''
    },
    'WAITING': {
        className: 'fas fa-spinner',
        color: 'yellow'
    },
    'CREATE_TASK': {
        color: 'yellow',
        className: 'fas fa-plus-circle'
    }
}

export const additionalDocList = [
    {
        label: 'Existing loan statement',
        value: 'loanStatementForExistingLoan'
    },
    {
        label: 'Income tax return',
        value: 'incomeTaxReturn'
    },
    {
        label: 'GST return',
        value: 'gstReturn'
    },
    {
        label: 'Sanction Letter of Lease Loan',
        value: 'sanctionLetterOfLoanLease'
    },
    {
        label: 'Unit economics',
        value: 'unitEconomics'
    },
    {
        label: 'Shareholding pattern',
        value: 'shareHolding'
    },
    {
        label: 'Company profile',
        value: 'companyProfile'
    },
    {
        label: 'Memorandum of Association',
        value: 'memorandumOfAssociation'
    },
    {
        label: 'Partnership Deed',
        value: 'partnershipDeed'
    },
    {
        label: 'Balance Sheet',
        value: 'balanceSheet'
    },
    {
        label: 'PnL statement',
        value: 'profitLossStatement'
    },
    {
        label: 'Projections',
        value: 'projections'
    }
]

export const fieldVerificationDropdown = [
    {
        label: 'Verify',
        code: 'VERIFIED',
        color: 'green',
        className: 'fas fa-check-circle'
    },
    {
        label: 'Reject',
        code: 'REJECTED',
        color: 'red',
        className: 'fas fa-times-circle'
    },
    {
        label: 'Create Task',
        code: 'CREATE_TASK',
        color: 'yellow',
        className: 'fas fa-plus-circle'
    }
]

export const agreementType = [
    {
        label: 'MOU',
        value: 'MOU'
    },
    {
        label: 'Agreement',
        value: 'AGREEMENT'
    },
    {
        label: 'Customer Consent',
        value: 'CUSTOMER_CONSENT'
    }
];

export const taskData = {
    code: 'Code',
    name: 'Name',
    description: 'Description',
    type: 'Type',
    parentTaskCode: 'Parent Task Code',
    taskcomment: 'Comment'
}

export const vehicleData = {
    assetOemCode: 'manufacturer Code',
    description:"Description",
    modelName: 'Model',
    modelYear: 'Year',
    category: 'Category',
    range: 'Range',
    batteryCapacity: 'Battery Capacity',
    energyConsuption:"Energy Consuption",
    maxSpeed:"Max Speed",
    acceleration:"Acceleration",
    warranty:"warranty"
}

export const manufactureData = {
    code: 'Code',
    name: 'Name',
    contactNumber: 'Contact Number',
    // createdAt: 'createdAt',
    website: 'Website',
    contactEmail: 'Contact Email',
    hqAddress:"Headquarter"

}

export const batteryData = {
    make: 'Make',                        
    code: 'Battery Code',                
    capacity: 'Capacity (kWh)',
    type: 'Type',                         
    warranty: 'Warranty',                 
    assetOemCode: 'OEM Code',             
    description: 'Description',                       
  }

  export const chargerData = {
    stationId: 'Station ID',                    
    assetOemCode: 'OEM Code',                   
    code: 'Charger Code',                       
    chargerRatedCapacityId: 'Rated Capacity ID', 
    typeOfChargedId: 'Charger Type',         
    ocpiTariffRateId: 'Tariff Rate ID',         
    noOfChargers: 'Number of Chargers',         
    chargerType: 'Charger Type',                
    ratedCapacity: 'Rated Capacity (kW)',       
    serviceCharge: 'Service Charge (INR)',      
    powerType: 'Power Type',                    
    connectorWorkingStatus: 'Connector Status', 
    wkStatus: 'Working Status',                 
    tariffRate: 'Tariff Rate (INR/kWh)',        
    createdAt: 'Created At',
    updatedAt: 'Last Updated'                   
  }


  export const chargingStationData = {
    cpoId: 'CPO ID',                           
    chargerId: 'Charger ID',                    
    stationId: 'Station ID',                    
    lat: 'Latitude',                            
    lng: 'Longitude',                           
    discomId: 'Discom ID',                      
    csDisplayno: 'Station Display Number',      
    stationName: 'Station Name',                
    address: 'Address',                         
    cityName: 'City Name',                      
    pinCode: 'Pin Code',                        
    cityId: 'City ID',                          
    stateId: 'State ID',                        
    stOwner: 'Station Owner',                   
    companyName: 'Company Name',                
    mobileNumber: 'Mobile Number',              
    contactPerson: 'Contact Person',            
    amenities: 'Amenities',                     
    avgCostDiscom: 'Average Cost (Discom)',     
    openingTime: 'Opening Time',                
    closingTime: 'Closing Time',                
    isTweentyFourSeven: 'Open 24/7?',           
    wkStatus: 'Working Status',                 
    isFourWheeler: 'Supports Four-Wheeler?',    
  }
  
  
  

export const initialFieldStatusConstant = {
    'cin': 'PENDING',
    'udyam': 'PENDING',
    'gst': 'PENDING',
    'businessIncorporationYear': 'PENDING',
    'pbtProjected': 'PENDING',
    'businessCategory': 'PENDING',
    'entityType': 'PENDING',
    'typeOfBusiness': 'PENDING',
    'existingVehicles': 'PENDING',
    'projectedRevenue': 'PENDING',
    'pbtLastProvisional': 'PENDING',
    'lastProvisionalRevenue': 'PENDING',
    'pbtLastAudited': 'PENDING',
    'revenueLastAudited': 'PENDING',
    'aadhaar': 'PENDING',
    'pan': 'PENDING',
    'numberOfVehicleRequired': 'PENDING',
    'amountRequired': 'PENDING',
    'city': 'PENDING',
    'manufacturer': 'PENDING',
    'model': 'PENDING',
    'variant': 'PENDING',
    'financialStatementLastFY': 'PENDING',
    'financialStatementLastFY-1': `PENDING`,
    'financialStatementLastFY-2': `PENDING`,
    'bankStatement': 'PENDING',
    'loanStatementForExistingLoan': 'PENDING',
    'incomeTaxReturn': 'PENDING',
    'gstReturn': 'PENDING',
    'sanctionLetterOfLoanLease': 'PENDING',
    'unitEconomics': 'PENDING',
    'shareHolding': 'PENDING',
    'companyProfile': 'PENDING',
    'memorandumOfAssociation': 'PENDING',
    'partnershipDeed': 'PENDING',
    'balanceSheet': 'PENDING',
    'profitLossStatement': 'PENDING',
    'projections': 'PENDING'
}