import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter,Button, Row, Col } from 'reactstrap';
import TextInput from '../../../components/text-input/TextInput';
import useClass from '../../../hooks/useClass';
import { addLenderNewAddress } from '../../../store/admin/adminData';


const CreateLenderAddress = ({ isOpen, toggle, lenderCode }) => {
  const [formData, setFormData] = useState({
        CGST: '',
        IGST: '',
        SGST: '',
        address: '',
        gstNumber: '',
        lenderCode: lenderCode|| '',
        panNumber: '',
        state: ''
      });
      const {dispatch} = useClass()

      const handleModelClose = () => {
        toggle(false);
      };
    
      // Handler for input changes
      const handleChange = (key, value) => {
          if(key === "IGST"){
            setFormData((prev)=>({
                ...prev,
                CGST:0,
                SGST:0,
            }))
          }
          if(key === "CGST" || key =="SGST"){
            setFormData((prev)=>({
              ...prev,
              IGST:0,
          }))
          }
        setFormData((prevData) => ({
          ...prevData,
          [key]: value
        }));
      };
    
      const addNewLenderAddress = async () => {
          dispatch(addLenderNewAddress({lenderCode,payload:formData}))
      }

  return (
    <Modal isOpen={isOpen} toggle={handleModelClose} centered>
      <ModalHeader toggle={handleModelClose}>Create Lender</ModalHeader>
      <ModalBody>
        <Row className="mb-3">
          <Col md={6}>
            <TextInput
              name="CGST"
              label="CGST"
              value={formData.CGST}
              required={true}
              handleInput={handleChange}
              type="number"
            />
          </Col>
          <Col md={6}>
            <TextInput
              name="IGST"
              label="IGST"
              value={formData.IGST}
              required={true}
              handleInput={handleChange}
              type="number"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <TextInput
              name="SGST"
              label="SGST"
              value={formData.SGST}
              required={true}
              handleInput={handleChange}
              type="number"
            />
          </Col>
          <Col md={6}>
            <TextInput
              name="lenderCode"
              label="Lender Code"
              value={formData.lenderCode}
              required={true}
              handleInput={handleChange}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <TextInput
              name="address"
              label="Address"
              value={formData.address}
              required={true}
              handleInput={handleChange}
            />
          </Col>
          <Col md={6}>
            <TextInput
              name="gstNumber"
              label="GST Number"
              value={formData.gstNumber}
              required={true}
              handleInput={handleChange}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <TextInput
              name="panNumber"
              label="PAN Number"
              value={formData.panNumber}
              required={true}
              handleInput={handleChange}
            />
          </Col>
          <Col md={6}>
            <TextInput
              name="state"
              label="State"
              value={formData.state}
              required={true}
              handleInput={handleChange}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleModelClose}>Close</Button>
        <Button color="primary" onClick={addNewLenderAddress}>Save changes</Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateLenderAddress
